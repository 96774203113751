/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    BezierCurve055: THREE.Mesh;
    INTER_ER___MEBEL__DLA_OKNA_FLAT_PART: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_TRUB_: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_: THREE.Mesh;
    OBJ_COKAL: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004: THREE.Mesh;
    OBJ_FASAD: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE: THREE.Mesh;
    OBOLOCKA___KROVLA_DLA_TRUB_: THREE.Mesh;
    OBJ_ROOF: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA001: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA001: THREE.Mesh;
  };
  materials: {
    ["Material.011"]: THREE.MeshStandardMaterial;
    ["Material.007"]: THREE.MeshStandardMaterial;
    ["Material.009"]: THREE.MeshStandardMaterial;
    ["Material.008"]: THREE.MeshStandardMaterial;
    ["Material.010"]: THREE.MeshStandardMaterial;
  };
};

export function House2(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "./meshes/houses/House2.glb"
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="BezierCurve055"
          castShadow
          receiveShadow
          geometry={nodes.BezierCurve055.geometry}
          material={materials["Material.011"]}
          position={[-7.146, -0.842, -2.312]}
          scale={0.982}
        />
        <mesh
          name="INTER_ER___MEBEL__DLA_OKNA_FLAT_PART"
          castShadow
          receiveShadow
          geometry={nodes.INTER_ER___MEBEL__DLA_OKNA_FLAT_PART.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA.geometry}
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J.geometry
          }
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J.geometry}
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA.geometry}
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2.geometry}
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV.geometry}
          material={materials["Material.008"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_TRUB_"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_TRUB_.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_.geometry}
          material={materials["Material.008"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART.geometry
          }
          material={materials["Material.010"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA.geometry
          }
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_
              .geometry
          }
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="OBJ_COKAL"
          castShadow
          receiveShadow
          geometry={nodes.OBJ_COKAL.geometry}
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="OBJ_FASAD"
          castShadow
          receiveShadow
          geometry={nodes.OBJ_FASAD.geometry}
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE.geometry}
          material={materials["Material.010"]}
          position={[-7.146, -0.842, -2.312]}
          scale={0.982}
        />
        <mesh
          name="OBOLOCKA___KROVLA_DLA_TRUB_"
          castShadow
          receiveShadow
          geometry={nodes.OBOLOCKA___KROVLA_DLA_TRUB_.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="OBJ_ROOF"
          castShadow
          receiveShadow
          geometry={nodes.OBJ_ROOF.geometry}
          material={materials["Material.008"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA001"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA001
              .geometry
          }
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA001"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA001.geometry
          }
          material={materials["Material.009"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./meshes/houses/House2.glb");
